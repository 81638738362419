import React from "react";
import "./blink-transform-business-component.css";

export function BlinkTransformBusinessComponent() {
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-md-12 mt-5 text-center">
                    <h1>Transform Your Business with BRT</h1>
                    <p>Discover the power of digital marketing, web design, and technology solutions with BRT. Elevate your brand and stay</p>
                    <p style={{marginTop:"-10px"}}>ahead of the competition in the digital world.</p>
                    <p>1/1/2024 . 1 min read</p>
                    <img className="mt-5 transform-business" src="transform-1.avif" alt="" />
                    <p className="mt-5">In this blog post, we will explore the latest trends in digital marketing and web</p>
                    <p style={{marginTop:"-10px"}}>design. Learn how BRT can help you unlock your business's full potential and</p>
                    <p style={{marginTop:"-10px"}}>achieve remarkable success.</p>
                </div>
            </div>
        </div>
        </>
    )
}