import React from "react";
import "./blink-stay-ahead-component.css";

export function BlinkStayAheadComponent() {
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-md-12 mt-5 text-center">
                    <h1>Stay Ahead of the Digital Curve with BRT</h1>
                    <p>Discover the latest strategies and technologies that can propel your business into the digital forefront with BRT's</p>
                    <p style={{marginTop:"-10px"}}>comprehensive services.</p>
                    <p>1/1/2024 . 1 min read</p>
                    <img className="mt-5 stay-ahead" src="transform-2.avif" alt="" />
                    <p className="mt-5">Learn how BRT's digital marketing and web design solutions can revolutionize </p>
                    <p style={{marginTop:"-10px"}}>your business. From SEO optimization to stunning website designs, we have </p>
                    <p style={{marginTop:"-10px"}}>everything you need to succeed in the digital age.</p>
                </div>
            </div>
        </div>
        </>
    )
}