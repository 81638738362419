import React from "react";

import { useState,useEffect } from "react";

function SliderContent({ activeIndex, sliderImage }) {
    
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        const offset = 60; 
        window.scrollTo({
            behavior: 'smooth',
            top: section.offsetTop - offset
        });
    };

    const handleNavigationClick = (id) => {
        scrollToSection(id);
    };

    const [slideTextVisible, setSlideTextVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSlideTextVisible(true);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const slideTitleStyle = {
    animation: slideTextVisible ? 'fadeInDown 0.5s ease-in-out forwards' : 'none'
  };

  const slideTextStyle = {
    animation: slideTextVisible ? 'fadeInUp 0.5s ease-in-out forwards' : 'none'
  };


  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image" src={slide.imageUrl} alt="" />
          <h1 className="slide-title" style={slideTitleStyle}>{slide.title}</h1>
          <p className="slide-text" style={slideTextStyle}>{slide.description}</p>
          <button type="button" className="btn btn-success slide-button" style={slideTextStyle} onClick={() => handleNavigationClick('about')}>{slide.buttonText}</button>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;