import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BlinkRandomComponent } from './components/Blink Random/blink-random-component';
import { BlinkTransformBusinessComponent } from './components/Transform Business/blink-transform-business-component';
import { BlinkStayAheadComponent } from './components/Blink Stay Ahead/blink-stay-ahead-component';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<BlinkRandomComponent />} />
      <Route path="transform-business" element={<BlinkTransformBusinessComponent />} />
      <Route path="stay-ahead" element={<BlinkStayAheadComponent />} />
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
