import React from "react";
import { useState,useEffect } from "react";
import SliderContent from "../Slider Images/SliderContent";
import Dots from "../Slider Images/Dots";
import Arrows from "../Slider Images/Arrows";
import sliderImage from "../Slider Images/sliderImage";
import "./blink-random-component.css";
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faMobileButton,faLocationDot,faBars,faXmark,faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp,faFacebook,faInstagram,faLinkedin,faFacebookF,faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const len = sliderImage.length - 1;

export function BlinkRandomComponent(props) {
    function showSidebar() {
        const sidebar = document.querySelector("#sidebar")
        sidebar.style.display = "flex"
    }

    function hideSidebar() {
        const sidebar = document.querySelector("#sidebar")
        sidebar.style.display = "none"
    }

    function StopMarquee() {
        const marquee = document.querySelector('marquee');
        marquee.stop();
    }
    
    function StartMarquee() {
        const marquee = document.querySelector('marquee');
        marquee.start();
    }
        
    const [activeIndex, setActiveIndex] = useState(0);
    
    const [selectedFilter, setSelectedFilter] = useState("*");

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
    };

    const navigate = useNavigate();

    const TransformBusinessClick = () => {
        navigate('/transform-business');
    };
    
    const StayAheadClick = () => {
        navigate('/stay-ahead');
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
        const header = document.getElementById("head");
        if (header) {
            if (window.scrollY > 0) {
                header.classList.add("fixed-top");
            } else {
                header.classList.remove("fixed-top");
            }
        }
    };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        const offset = 60; 
        window.scrollTo({
            behavior: 'smooth',
            top: section.offsetTop - offset
        });
    };

    const handleNavigationClick = (id) => {
        scrollToSection(id);
    };

    useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    
    return(
        <>
        <div id="header">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <FontAwesomeIcon className="icon mt-2" icon={faEnvelope} /> <span>blinkrtec5@gmail.com</span>
                        <FontAwesomeIcon className="icon mt-2 ms-2" icon={faMobileButton} /> <span>+91 9226476524</span>
                    </div>
                    <div className="col-md-8 mt-2">
                        <FontAwesomeIcon className="i" icon={faWhatsapp} />
                        <FontAwesomeIcon className="i" icon={faFacebook} />
                        <FontAwesomeIcon className="i" icon={faInstagram} />
                        <FontAwesomeIcon className="i" icon={faLinkedin} />
                    </div>
                </div>
            </div>
        </div>

        <div id="sidebar">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mt-2">
                <FontAwesomeIcon onClick={hideSidebar} icon={faXmark} />
                </div>
                <div className="col-md-8 mt-4">
                    <ul id="menu">
                        <li onClick={() => handleNavigationClick('home')}>Home</li>
                        <li onClick={() => handleNavigationClick('about')}>About Us</li>
                        <li onClick={() => handleNavigationClick('services')}>Services</li>
                        <li onClick={() => handleNavigationClick('technology')}>Technology</li>
                        <li onClick={() => handleNavigationClick('portfolio')}>Portfolio</li>
                        <li onClick={() => handleNavigationClick('contact')}>Contact</li>
                        <button type="button" className="btn btn-outline-success ms-4" onClick={() => handleNavigationClick('about')}>
                            Get Started 
                        </button>
                    </ul>
                </div>
            </div>
        </div>
        </div>

        <div id="head">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mt-2">
                    <img src="blink_random.png" alt="" style={{width:"100px",height:"100px",marginTop:"-10px",marginLeft:"-40px"}}/>
                    <h1 id="logo">BLINKRANDOM</h1>
                </div>
                <div className="col-md-8 mt-2">
                    <ul id="menu">
                        <li className="hideOnMobile" onClick={() => handleNavigationClick('home')}>Home</li>
                        <li className="hideOnMobile" onClick={() => handleNavigationClick('about')}>About Us</li>
                        <li className="hideOnMobile" onClick={() => handleNavigationClick('services')}>Services</li>
                        <li className="hideOnMobile" onClick={() => handleNavigationClick('technology')}>Technology</li>
                        <li className="hideOnMobile" onClick={() => handleNavigationClick('portfolio')}>Portfolio</li>
                        <li className="hideOnMobile" onClick={() => handleNavigationClick('contact')}>Contact</li>
                        <button type="button" className="btn btn-outline-success ms-4 hideOnMobile" onClick={() => handleNavigationClick('about')}>
                            Get Started 
                        </button>
                        <FontAwesomeIcon className="menu-button" onClick={showSidebar} icon={faBars} />
                    </ul>
                </div>
            </div>
        </div>
        </div>
        
        <div className="slider-container" id="home">
            <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
            <Arrows 
            prevSlide={() => 
                setActiveIndex(activeIndex < 1 ? len : activeIndex - 1) 
            }
            nextSlide={() => 
                setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
            } />
            <Dots activeIndex={activeIndex} sliderImage={sliderImage}
            onclick={(activeIndex) => setActiveIndex(activeIndex)} />
        </div>
        
        <div className="container">
            <div className="row">
                <div className="col-md-4 mt-5">
                <img src="web-app.png" alt="" style={{height:"50px",width:"50px"}} />
                <h4 className="mt-3">Web Application</h4>
                <p style={{textAlign:"justify"}}>A web application is a software program that utilizes web browsers and web technology to perform tasks over the internet. It allows users to interact with data, services, or other users through a web interface. Web applications are accessed through URLs and do not require installation on the user's device.</p>
                </div>
                <div className="col-md-4 mt-5">
                <img src="digital-marketing.png" alt="" style={{height:"50px",width:"50px"}} />
                <h4 className="mt-3">Digital Marketing</h4>
                <p style={{textAlign:"justify"}}>Digital marketing is the strategic promotion of products or services using various online channels, such as websites, social media, email, and search engines, to reach and engage with target audiences. It encompasses a range of tactics aimed at driving traffic, conversions, and brand awareness in the digital realm.</p>
                </div>
                <div className="col-md-4 mt-5">
                <img src="cyber-security.png" alt="" style={{height:"50px",width:"50px"}} />
                <h4 className="mt-3">Cyber Security</h4>
                <p style={{textAlign:"justify"}}>Cybersecurity refers to the practice of protecting computer systems, networks, and data from digital attacks, unauthorized access, and breaches. It encompasses various technologies, processes, and practices aimed at safeguarding information assets and ensuring confidentiality, integrity, and availability in the digital realm.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div id="about" className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">ABOUT US</h1>
                    <p>We are a dynamic agency specializing in digital marketing and website development. Our team of experts is committed to providing tailored solutions that drive real results for your business.At the core of our existence is a commitment to empower businesses with impactful online solutions, driving success and fostering growth in the digital landscape.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <h4 className="mt-4">We are a dynamic agency specializing in digital marketing and website development.</h4>
                    <p style={{fontStyle:"italic",textAlign:"justify"}}>Welcome to Blink Random Technologies, where innovation meets design excellence. As a leading force in the realm of website design, we are passionate about transforming ideas into visually captivating digital experiences.</p>
                    <p style={{textAlign:"justify"}}>At Blink Random Technologies, we believe that every pixel counts. Our team of seasoned designers, developers, and creatives work tirelessly to blend cutting-edge technology with sleek aesthetics, ensuring that each website we craft not only meets but exceeds the expectations of our clients.</p>
                    <p style={{textAlign:"justify"}}>What sets us apart is our unwavering commitment to understanding our clients' unique visions and translating them into tangible, user-centric solutions. Whether you're a burgeoning startup or a well-established enterprise, we pride ourselves on delivering bespoke designs that resonate with your target audience and elevate your online presence.</p>
                    <p style={{textAlign:"justify"}}>Discover the Blink Random Technologies difference today. Let's build something extraordinary together.</p>
                </div>
                <div className="col-md-6 mt-4">
                    <img src="about.jpg" alt="" style={{width:"100%"}}/>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center ">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">WHO WE ARE</h1>
                </div>
                <div className="col-xl-7 mt-4 who-we-are">
                    <img src="who-we-are.avif" alt="" />
                </div>
                <div className="col-xl-5 mt-4">
                <p style={{textAlign:"justify"}}>Blink Random Technologies is more than a company – it's a collective of passionate individuals driven by a shared vision of transforming businesses through strategic digital initiatives. Established with a mission to redefine the standards of excellence in the industry, we bring together a diverse team of professionals with a wealth of experience and a relentless pursuit of innovation.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div id="services" className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">SERVICES</h1>
                </div>  
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-4 mt-4 ms-3" style={{background:"#fbfbfb",width:"350px",height:"380px",borderTopRightRadius:"70px",borderBottomLeftRadius:"70px",boxShadow: "0px 5px 90px 0px rgba(110, 123, 131, 0.1)"}}>
                <Icon className="mt-4 d-flex m-auto" icon="ri:dribbble-fill" style={{color: "#47aeff",fontSize:"50px"}} />
                <h5 className="text-center">Digital Marketing Services</h5>
                    <ol type="1">
                        <li>Web Design and Development</li>
                        <li>Search Engine Optimization (SEO)</li>
                        <li>Social Media Management</li>
                        <li>Content Marketing</li>
                        <li>Pay-Per-Click (PPC) Advertising</li>
                        <li>Email Marketing</li>
                        <li>E-commerce Solutions</li>
                    </ol>
                    <button className="btn btn-success" style={{display:"flex",margin:"auto",marginTop:"40px"}} >Digital Quote</button>
                </div>
                <div className="col-md-4 mt-4 ms-3" style={{background:"#fbfbfb",width:"350px",height:"380px",borderTopRightRadius:"70px",borderBottomLeftRadius:"70px",boxShadow: "0px 5px 90px 0px rgba(110, 123, 131, 0.1)"}}>
                <Icon className="mt-4 d-flex m-auto" icon="bx:file"  style={{color: "#ffa76e",fontSize:"50px"}} />
                <h5 className="text-center">Website Development Services</h5>
                    <ol type="8">
                        <li>Custom Website Development</li>
                        <li>Dashboard Development</li>
                        <li>Website Hosting and Domain Registration</li>
                        <li>Website Maintenance</li>
                    </ol>
                    <button className="btn btn-success" style={{display:"flex",margin:"auto",marginTop:"90px"}}>Web Quote</button>
                </div>
                <div className="col-md-4 mt-4 ms-3" style={{background:"#fbfbfb",width:"350px",height:"380px",borderTopRightRadius:"70px",borderBottomLeftRadius:"70px",boxShadow: "0px 5px 90px 0px rgba(110, 123, 131, 0.1)"}}>
                <Icon className="mt-4 d-flex m-auto" icon="bx:tachometer"  style={{color: "#e80368",fontSize:"50px"}} />
                <h5 className="text-center">Additional Services</h5>
                    <ol type="12">
                        <li>Google Services Integration</li>
                        <li>Google My Business</li>
                        <li>Audits and Reports</li>
                        <li>Google Analytics</li>
                        <li>GTM Integration</li>
                        <li>Software Development Contracts</li>
                        <li>Data Services</li>
                        <li>Data Management</li>
                    </ol>
                    <button className="btn btn-success" style={{display:"flex",margin:"auto"}}>Google Quote</button>
                </div>
            </div>
        </div>

       <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">CLIENTS</h1>
                    <p>BRT's services have transformed our business. Highly recommend! The team at BRT is professional, efficient, and delivers outstanding work. Exceptional results! BRT exceeded our expectations. Working with BRT has been a game-changer for our brand.</p>
                </div>
                <div className="col-xl-6 mt-5">
                    <img className="client" src="photo-1.avif" alt="" onClick={TransformBusinessClick} />
                    <h1 className="mt-3">Transform Your Business with BRT</h1>
                    <p style={{textAlign:"justify"}}>Discover the power of digital marketing, web design, and technology solutions with BRT. Elevate your brand and stay ahead of the competition in the digital world.</p>
                    <p>1/1/2024 . 1 min read</p>
                </div>
                <div className="col-xl-6 mt-5">
                    <img className="client" src="photo-2.avif" alt="" onClick={StayAheadClick} />
                    <h1 className="mt-3">Stay Ahead of the Digital Curve with BRT</h1>
                    <p>Discover the latest strategies and technologies that can propel your business into the digital forefront with BRT's comprehensive services.</p>
                    <p>1/1/2024 . 1 min read</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mt-5">
                    <h1 className="section-title text-center position-relative pb-3 mb-4">Get in Touch</h1>
                    <p>Reach out to us today and let's discuss your digital needs</p>
                </div>
                <div className="col-md-6 m-auto mt-4 form-group">
                    <label>Name*</label>
                    <input type="text" className="form-control" placeholder="Your Name" />
                    <label className="mt-4">Your email*</label>
                    <input type="text" className="form-control" placeholder="Your email address" />
                    <label className="mt-4">Message*</label>
                    <textarea name="" className="form-control" placeholder="Enter your message" />
                    <button className="btn btn-success mt-4 m-auto d-flex">Submit</button>
                </div>
            </div> 
        </div>

        <div className="container">
            <div className="row">
                <div id="technology" className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">TECHNOLOGY</h1>
                    <p>We are a dynamic agency specializing in digital marketing and website development. Our team of experts is committed to providing tailored solutions that drive real results for your business.At the core of our existence is a commitment to empower businesses with impactful online solutions, driving success and fostering growth in the digital landscape.</p>
                </div>
                <div className="col-xl-6 mt-5">
                    <img src="Web-Design.jpg" alt="" style={{width:"100%",borderTopRightRadius:"50px",borderBottomLeftRadius:"50px"}}/>
                    <h1 className="mt-3">Web Design</h1>
                    <p style={{textAlign:"justify"}}>Web design is the process of creating and arranging visual elements, content, and functionality on websites to make them user-friendly, visually appealing, and effective in conveying information or facilitating interaction with users.</p>
                </div>
                <div className="col-xl-6 mt-5">
                    <img src="Application-Design.avif" alt="" style={{width:"100%",height:"308px",borderTopLeftRadius:"50px",borderBottomRightRadius:"50px"}}/>
                    <h1 className="mt-3">Application Design</h1>
                    <p style={{textAlign:"justify"}}>Application design refers to the process of conceptualizing, planning, and creating the user interface (UI) and user experience (UX) of software applications. It involves designing the layout, functionality, and interactions within the application to ensure usability, efficiency, and user satisfaction.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div id="technology" className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">TECHNOLOGY BE USED</h1>
                    <marquee onMouseOver={StopMarquee} onMouseOut={StartMarquee} scrollamount="15" direction="left" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        <img src="html.webp" alt="" className="me-4" style={{ width: "100px", height: "100px" }} />
                        <img src="css.png" alt="" className="me-4" style={{ width: "100px", height: "100px" }} />
                        <img src="bootstrap.png" alt="" className="me-4" style={{ width: "100px", height: "100px" }} />
                        <img src="javascript.png" alt="" className="me-4" style={{ width: "100px", height: "100px" }} />
                        <img src="nodejs.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="expressjs.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="mongodb.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                    </marquee>
                    <marquee onMouseOver={StopMarquee} onMouseOut={StartMarquee} scrollamount="15" direction="right" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    <img src="git.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="github.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="aws.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="redis.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="postman.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="react.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                        <img src="mysql.png" alt="" className="me-4" style={{ width:"100px", height:"100px" }} />
                    </marquee>
                </div>
            </div> 
        </div>       
        
        <div className="container">
            <div className="row">
                <div id="portfolio" className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">PORTFOLIO</h1>
                    <p>We are a dynamic agency specializing in digital marketing and website development. Our team of experts is committed to providing tailored solutions that drive real results for your business. At the core of our existence is a commitment to empower businesses with impactful online solutions, driving success and fostering growth in the digital landscape.</p>
                    <ul className="mt-4" id="portfolio-flters">
                        <li onClick={() => handleFilterClick("*",)} className={selectedFilter === "*" ? "filter-active" : ""}>All</li>
                        <li onClick={() => handleFilterClick(".filter-app")} className={selectedFilter === ".filter-app" ? "filter-active" : ""}>App</li>
                        <li onClick={() => handleFilterClick(".filter-card")} className={selectedFilter === ".filter-card" ? "filter-active" : ""}>Tech</li>
                        <li onClick={() => handleFilterClick(".filter-web")} className={selectedFilter === ".filter-web" ? "filter-active" : ""}>Web</li>
                    </ul>
                </div>
                <div className="col-xl-4 text-sm-center mt-5 port-folio" style={{ display: selectedFilter === "*" || selectedFilter === ".filter-card" ? "block" : "none" }}>
                    <img src="creative-solutions.avif" alt="" style={{ width: "370px", borderRadius: "25px" }} />
                    <h4 className="mt-3">Creative Solutions</h4>
                </div>
                <div className="col-xl-4 text-sm-center mt-5 port-folio" style={{ display: selectedFilter === "*" || selectedFilter === ".filter-web" ? "block" : "none" }}>
                    <img src="web-design.avif" alt="" style={{ width: "370px", borderRadius: "25px" }} />
                    <h4 className="mt-3">Web Design</h4>
                </div>
                <div className="col-xl-4 text-sm-center mt-5 port-folio" style={{ display: selectedFilter === "*" || selectedFilter === ".filter-card" ? "block" : "none" }}>
                    <img src="technology-solutions.avif" alt="" style={{ width: "370px", borderRadius: "25px" }} />
                    <h4 className="mt-3">Technology Solutions</h4>
                </div>
                <div className="col-xl-4 text-sm-center mt-5 port-folio" style={{ display: selectedFilter === "*" || selectedFilter === ".filter-web" ? "block" : "none" }}>
                    <img src="brand-development.avif" alt="" style={{ width: "370px", borderRadius: "25px" }} />
                    <h4 className="mt-3">Brand Development</h4>
                </div>
                <div className="col-xl-4 text-sm-center mt-5 port-folio" style={{ display: selectedFilter === "*" || selectedFilter === ".filter-app" || selectedFilter === ".filter-web" ? "block" : "none" }}>
                    <img src="social-media.avif" alt="" style={{ width: "370px", borderRadius: "25px" }} />
                    <h4 className="mt-3">Social Media</h4>
                </div>
                <div className="col-xl-4 text-sm-center mt-5 port-folio" style={{ display: selectedFilter === "*" || selectedFilter === ".filter-app" ? "block" : "none" }}>
                    <img src="content-creation.avif" alt="" style={{ width: "370px", borderRadius: "25px" }} />
                    <h4 className="mt-3">Content Creation</h4>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div id="contact" className="col-md-12 text-center">
                    <h1 className="section-title text-center position-relative pb-3 mb-4 mt-5">CONTACT</h1>
                    <h3>If You Have Any Query, Feel Free To Contact Us</h3>
                </div> 
                <div className="col-xl-6 mt-4 info" id="contact-info">
                <FontAwesomeIcon icon={faLocationDot} style={{color: "#5cb874",fontSize:"20px"}}/>
                <h4 className="ms-2" style={{display:"inline-block"}}>Location:</h4>
                <p className="ms-4">Mumbai Naigao</p>
                <FontAwesomeIcon icon={faEnvelope} style={{color: "#5cb874",fontSize:"20px"}}/>
                <h4 className="ms-2" style={{display:"inline-block"}}>Email</h4>
                <p className="ms-4">blinkrtec5@gmail.com</p>
                <FontAwesomeIcon icon={faMobileButton} style={{color: "#5cb874",fontSize:"20px"}}/>
                <h4 className="ms-2" style={{display:"inline-block"}}>Call:</h4>
                <p className="ms-4">+91 9226476524</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47368.39682225005!2d-72.489385!3d42.09623!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e6e85bbff73363%3A0x5241d930e1909b8f!2sHilltop%20St%2C%20Springfield%2C%20MA%2001128!5e0!3m2!1sen!2sus!4v1710217775147!5m2!1sen!2sus" title="google-map" width="350" height="250" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div> 
                <div className="col-xl-6 form-group email-form mt-4" id="email-info">
                            <label className="mt-4">Name*</label>
                            <input type="text" className="form-control" placeholder="Your name" />
                            <label className="mt-4">Your email*</label>
                            <input type="text" className="form-control" placeholder="Your email address" />
                            <label className="mt-4">Message*</label>
                            <textarea name="" className="form-control" placeholder="Enter your message" style={{height:"225px"}}/>
                            <button className="btn btn-success mt-4 d-flex m-auto">Send Message</button>
                </div>
            </div>
        </div>
        
        <div class="container-fluid text-light mt-5 wow fadeInUp" data-wow-delay="0.1s" style={{background:"#091E3E"}}>
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-4 col-md-6 footer-about">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                        <a class="navbar-brand">
                            <h1 class="m-0 text-white"><FontAwesomeIcon icon={faUserTie} className="me-2" />BlinkRandom</h1>
                        </a>
                        <p class="mt-3 mb-4">Welcome to the digital realm where creativity meets technology. At Blink Random Technologies, we are the catalysts of your digital success. Explore our comprehensive services in digital marketing and website development, and let's embark on a journey to transform your brand.</p>
                        <form action="">
                            <div class="input-group">
                                <input type="text" class="form-control border-white p-3" placeholder="Your Email" />
                                <button class="btn btn-dark">Sign Up</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-8 col-md-6">
                    <div class="row gx-5">
                        <div class="col-lg-4 col-md-12 pt-5 mb-5">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="text-light mb-0">Get In Touch</h3>
                            </div>
                            <div class="d-flex mb-2">
                                <i class="bi bi-geo-alt text-primary me-2"></i>
                                <p class="mb-0">Mumbai Naigao</p>
                            </div>
                            <div class="d-flex mb-2">
                                <i class="bi bi-envelope-open text-primary me-2"></i>
                                <p class="mb-0">blinkrtec5@gmail.com</p>
                            </div>
                            <div class="d-flex mb-2">
                                <i class="bi bi-telephone text-primary me-2"></i>
                                <p class="mb-0">+91 9226476524</p>
                            </div>
                            <div class="d-flex mt-4">
                                <a class="btn btn-primary btn-square me-2" ><FontAwesomeIcon icon={faWhatsapp} /></a>
                                <a class="btn btn-primary btn-square me-2" ><FontAwesomeIcon icon={faFacebookF} /></a>
                                <a class="btn btn-primary btn-square me-2" ><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                <a class="btn btn-primary btn-square" ><FontAwesomeIcon icon={faInstagram} /></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="text-light mb-0">Quick Links</h3>
                            </div>
                            <div class="link-animated d-flex flex-column justify-content-start">
                                <a class="text-light mb-2"  style={{textDecoration: "none",cursor:"pointer"}} onClick={() => handleNavigationClick('home')}><i class="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none",cursor:"pointer"}} onClick={() => handleNavigationClick('about')}><i class="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none",cursor:"pointer"}} onClick={() => handleNavigationClick('services')}><i class="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Meet The Team</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                                <a class="text-light"  style={{textDecoration: "none",cursor:"pointer"}} onClick={() => handleNavigationClick('contact')}><i class="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="text-light mb-0">Popular Links</h3>
                            </div>
                            <div class="link-animated d-flex flex-column justify-content-start">
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Domain Registration</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Web Services</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Digital Marketing</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>SEO</a>
                                <a class="text-light mb-2"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Cyber Security</a>
                                <a class="text-light"  style={{textDecoration: "none"}}><i class="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid text-white" style={{background: "#061429"}}>
    <div class="container text-center">
        <div class="row justify-content-end">
            <div class="col-lg-8 col-md-6">
                <div class="d-flex align-items-center justify-content-center" style={{height: "75px"}}>
                    <p class="mb-0">&copy; <a class="text-white border-bottom"  style={{textDecoration: "none"}}>Blink Random Technologies</a>. All Rights Reserved. 
                    Designed by <a class="text-white border-bottom"  style={{textDecoration: "none"}}>Saurabh Karn <span style={{color:"#5cb874"}}>(Blink Random Technologies)</span></a></p>
                </div>
            </div>
        </div>
    </div>
</div>
</>
    )
}