import FirstSlideImage from "../../assets/slide-1.jpg";
import SecondSlideImage from "../../assets/slide-2.jpg";
import ThirdSlideImage from "../../assets/slide-3.jpg";

const slidesData = [
  {
    title: "Welcome to Blink Random",
    description: "Welcome to the digital realm where creativity meets technology. At Blink Random Technologies, we are the catalysts of your digital success. Explore our comprehensive services in digital marketing and website development, and let's embark on a journey to transform your brand.",
    imageUrl: FirstSlideImage,
    buttonText: "Read More",
  },
  {
    title: "Web App Development",
    description: "A web application is a software program that utilizes web browsers and web technology to perform tasks over the internet. It allows users to interact with data, services, or other users through a web interface. Web applications are accessed through URLs and do not require installation on the user's device.",
    imageUrl: SecondSlideImage,
    buttonText: "Read More",
  },
  {
    title: "Cyber Security",
    description: "Cybersecurity refers to the practice of protecting computer systems, networks, and data from digital attacks, unauthorized access, and breaches. It encompasses various technologies, processes, and practices aimed at safeguarding information assets and ensuring confidentiality, integrity, and availability in the digital realm.",
    imageUrl: ThirdSlideImage,
    buttonText: "Read More",
  },
];

export default slidesData;